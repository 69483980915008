import React from 'react';
import styles from "../style";

const ProblemSolution = ({ problem, solution }) => (
  <div className="flex justify-between items-center mb-4 bg-discount-gradient p-4 rounded-md">
    <div className="flex-1">
      <span className="text-red-500">✕</span>
      <span className="text-dimWhite ml-2">{problem}</span>
    </div>
    <div className="flex-1 text-right">
      <span className="text-green-500">✓</span>
      <span className="text-white ml-2">{solution}</span>
    </div>
  </div>
);

const Help = () => {
  const problems_solutions = [
    { problem: "Manual lead generation, which can get expensive ($300+ per lead)", solution: "Engage customers immediately upon visit to website" },
    { problem: "Limited sales teams with 9-5 availability (71% don't have time to sell)", solution: "24/7 automated sales solution to generate revenue, frees up sales team" },
    { problem: "Loss of customers due to unavailability, 82% expect immediate response", solution: "Personalized & engaging customer experience in an instant" },
    { problem: "Significant time + money spent to solve customer issues ($15-200 per call)", solution: "Happy customers due to quick, automatic response times" },
    { problem: "75% of customers frustrated due to lengthy response times", solution: "Happy customer, more referrals due to quick response time" },
  ];

  return (
    <section
      className={`${styles.flexCenter} flex-col bg-black-gradient-2 rounded-[20px] box-shadow pt-12 mt-[35px]`}
    >
      <div className="flex flex-col w-full">
        <div className={`${styles.flexCenter} flex-col w-full`}>
          <div className="flex justify-center">
            <h2 className={`${styles.heading2} flex flex-col ms:flex-row ms:gap-0`}>
              <span className="ms:inline">How Can</span>
              <span className="mt-[3px] ms:mt-0 ms:ml-[10px]">We Help?</span>
            </h2>
          </div>
          <p className={`${styles.paragraph} max-w-[750px] mt-5 mb-10 text-center`}>
            Our AI-driven solutions maximizes your potential to generate new revenue, cut service costs, and deliver exceptional 24/7 customer service.
          </p>
        </div>
        <div className="mt-[40px] flex flex-col ms:flex-row justify-between">
          <h2 className={`${styles.heading2} text-center ms:text-left mb-[0px] ms:mb-0 text-[28px] ms:text-[32px] leading-tight`}>
            <span className="ms:hidden whitespace-nowrap">Before &amp; After</span>
            <span className="hidden ms:inline">Before SoloScale</span>
          </h2>
          <h2 className={`${styles.heading2} mb-[24px] text-center ms:text-right text-[28px] ms:text-[32px] leading-tight`}>
            <span className="ms:hidden">SoloScale</span>
            <span className="hidden ms:inline">After SoloScale</span>
          </h2>
        </div>
        {problems_solutions.map((item, index) => (
          <ProblemSolution key={index} problem={item.problem} solution={item.solution} />
        ))}
      </div>
    </section>
  );
};

export default Help;