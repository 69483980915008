import React, { useState, useRef } from 'react';
import { useSprings, animated, to as interpolate } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import { feedback } from "../constants";
import styles from "../style";
import { quotes } from '../assets';

// Animation helper functions
const to = (i) => ({
  x: 0,
  y: 0,
  scale: 1,
  rot: -5 + Math.random() * 10,
  delay: i * 100,
});

const from = (_i) => ({ x: 0, y: -500, rot: 0, scale: 1.5 });

const trans = (r, s) =>
  `perspective(1500px) rotateX(10deg) rotateY(${r / 10}deg) rotateZ(${r}deg) scale(${s})`;

const Testimonials2 = () => {
  const [order, setOrder] = useState(feedback.map((_, index) => index));
  const timeoutRef = useRef(null);

  // Initialize springs for animations
  const [props, api] = useSprings(feedback.length, i => ({
    ...to(i),
    from: from(i),
  }));

  // Drag gesture handler
  const bind = useDrag(({ args: [originalIndex], active, movement: [mx] }) => {
    const currentIndex = order.indexOf(originalIndex);
    
    if (!active && Math.abs(mx) > 20) {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        setOrder(order => {
          const newOrder = [...order];
          const movedItem = newOrder.splice(currentIndex, 1)[0];
          newOrder.push(movedItem);
          return newOrder;
        });
      }, 200);
    }

    // Animate card while dragging
    api.start(i => {
      const currentOrderIndex = order.indexOf(i);
      if (currentOrderIndex !== currentIndex) return;
      
      const x = active ? mx : 0;
      const rot = mx / 100;
      const scale = active ? 1.1 : 1;
      
      return {
        x,
        rot,
        scale,
        immediate: active,
        config: { friction: 50, tension: active ? 800 : 500 },
      };
    });
  });

  return (
    <section className={`${styles.paddingY} ${styles.flexCenter} w-full min-h-screen bg-[#1e40af]`}>
      {/* Header Section */}
      

      {/* Testimonials Container */}
      <div className="relative flex flex-col md:flex-row gap-8 items-center justify-center max-w-6xl mx-auto">
        {props.map((springProps, i) => {
          const { x, y, rot, scale } = springProps;
          const orderIndex = order.indexOf(i);
          
          return (
            <animated.div
              key={i}
              className="w-full md:w-1/3"
              style={{
                x,
                y,
                zIndex: feedback.length - orderIndex,
              }}
            >
              <animated.div
                {...bind(i)}
                style={{
                  transform: interpolate([rot, scale], trans),
                  touchAction: 'none',
                }}
                className="bg-[#14101d] p-8 rounded-xl shadow-2xl transition-transform transform hover:scale-105 hover:shadow-xl"
              >
                {/* Quote Icon */}
                <div className="mb-6">
                  <img 
                    src={quotes} 
                    alt="quotes" 
                    className="w-10 h-10 opacity-80"
                  />
                </div>

                {/* Testimonial Content */}
                <div className="min-h-[200px] mb-6">
                  <p className="text-gray-300 text-xl leading-relaxed">
                    {feedback[i].content}
                  </p>
                </div>

                {/* Author Info */}
                <div className="flex items-center">
                  <img 
                    src={feedback[i].img} 
                    alt={feedback[i].name} 
                    className="w-16 h-16 rounded-full mr-4 object-cover border-2 border-gray-400"
                  />
                  <div>
                    <h4 className="text-white font-semibold text-xl">
                      {feedback[i].name}
                    </h4>
                    <p className="text-gray-400 text-lg">
                      {feedback[i].title}
                    </p>
                  </div>
                </div>
              </animated.div>
            </animated.div>
          );
        })}
      </div>
    </section>
  );
};

export default Testimonials2;
