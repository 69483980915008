import React from 'react';
import styles from "../style";
import { discount, robot, robot_hand, SoloScale } from "../assets";
import GetStarted from "./GetStarted";
import TypingAnimation from "./Type";
import lightbulb from "../assets/lightbulb.png";
import Arrow from "./Arrow";

const Hero = () => {
  const words = ["Innovative", "Powerful", "Efficient", "Reliable"];

  return (
    <section
      id="home"
      className="flex flex-col lg:flex-row min-h-[90vh] w-full pt-32 sm:pt-36 lg:pt-40 px-4 sm:px-6 lg:px-10 mb-20 pb-16"
    >
      {/* Left Content Column */}
      <div className="flex flex-1 flex-col justify-center items-center text-center lg:justify-center px-0 sm:px-6 xl:px-0 lg:pr-4 mt-8 lg:mt-12">
        <div className="w-full max-w-[600px] mx-auto">
          <h1 className="font-poppins font-semibold text-4xl sm:text-5xl lg:text-6xl text-white leading-[1.2] sm:leading-[1.2] lg:leading-[1.2]">
            <span className="bg-gradient-to-r from-yellow-400 to-yellow-600 bg-clip-text text-transparent">
              Boost
            </span>{" "}
            Revenue.
            <br />
            <span className="bg-gradient-to-r from-yellow-400 to-yellow-600 bg-clip-text text-transparent">
              Reduce
            </span>{" "}
            Costs.
          </h1>

          <p className="font-poppins text-dimWhite text-base sm:text-lg lg:text-xl max-w-[500px] mt-6 sm:mt-8 mx-auto">
            Our team leverages the latest generative AI technologies to help you
            generate new revenue and reduce service costs while providing
            excellent customer service, 24/7.
          </p>

          {/* CTA Button */}
          <div className="mt-8 sm:mt-10">
            <button className="py-3 px-5 sm:py-4 sm:px-6 bg-yellow-500 hover:bg-yellow-600 transition-colors rounded-lg text-black font-semibold text-base sm:text-lg">
              Get Started
            </button>
          </div>
        </div>
      </div>

      {/* Right Image Column */}
      <div className="flex flex-1 justify-center items-center mt-16 lg:mt-12 relative">
        <div className="relative w-full max-w-[250px] sm:max-w-[300px] lg:max-w-[400px] mx-auto">
          <img
            src={lightbulb}
            alt="AI Innovation"
            className="w-full h-auto rounded-lg border-2 border-yellow-300 transform transition-all duration-500 hover:-translate-y-2 hover:scale-105"
            style={{
              boxShadow: "0 0 20px 10px rgba(255, 215, 0, 0.3)",
            }}
          />

          {/* Gradient Effects */}
          <div className="absolute z-[-1] w-[80%] h-[80%] top-0 pink__gradient opacity-60" />
          <div className="absolute z-[-1] w-[90%] h-[90%] rounded-full white__gradient opacity-60" />
        </div>
      </div>

      {/* Custom CSS for responsive margin-top */}
      <style jsx>{`
        @media (max-height: 700px) {
          .mt-responsive {
            margin-top: calc(15vh + 128px);
          }
        }

        @media (min-height: 701px) and (max-height: 900px) {
          .mt-responsive {
            margin-top: calc(10vh + 128px);
          }
        }

        @media (min-height: 901px) {
          .mt-responsive {
            margin-top: calc(8vh + 128px);
          }
        }
      `}</style>
    </section>
  );
};

export default Hero;