import styles from "./style";
import { Arrow, Billing, Business, CardDeal, Niches, CursorEffect, Calendly, CTA, Footer, Navbar, Stats, Clients, Testimonials2, ContactSection, Testimonials, Hero, Plans, Help, SampleServices } from "./components";
import { stanford, websize, buildspace, Alovie } from "./assets";
import partners from "./parteners";

const App = () => {
  

  return (
      <div className="bg-primary w-full overflow-hidden">
          <div className={`${styles.paddingX} ${styles.flexCenter}`}>
              <div className={`${styles.boxWidth}`}>
                  <Navbar/>
              </div>
          </div>
          <div id="home" className={`bg-primary ${styles.flexStart}`}>
              <div className={`${styles.boxWidth}`}>
                  <Hero/>
              </div>
          </div>
          {/*<div className={"flex items-center justify-center"}>*/}
          {/*    <Arrow/>*/}
          {/*</div>*/}
          <div id="home" className={`bg-primary ${styles.flexStart}`}>
              <div className={`${styles.boxWidth} `}>
                  <Niches/>
              </div>
          </div>
          {/*<div className={"flex items-center justify-center"}>*/}
          {/*    <Arrow/>*/}
          {/*</div>*/}

          <div className={`bg-primary ${styles.paddingX} ${styles.flexCenter}`}>
              <div className={`${styles.boxWidth}`}>
                  {/*<Stats />*/}

                  <Help/>
                  <div id="services" className={`mb-4`}>
                      <SampleServices/>
                  </div>
                  {/*<div className={"flex items-center justify-center"}>*/}
                  {/*    <Arrow/>*/}
                  {/*</div>*/}
                  <div id="partners" className={"mt-4"}>
                      <Clients partners={partners}/>
                  </div>

                  <div id="testimonials">
                      <Testimonials/>
                  </div>
                  {/*<div className={"flex items-center justify-center"}>*/}
                  {/*    <Arrow/>*/}
                  {/*</div>*/}

                  <div id="process">
                      <Business/>
                  </div>
                  {/*<div className={"flex items-center justify-center"}>*/}
                  {/*    <Arrow/>*/}
                  {/*</div>*/}

                  <CTA/>

                  <div id="get-started" className={``}>
                      <Calendly/>
                  </div>


                  <div id="contact" className={``}>
                      <ContactSection/>
                  </div>

                  <Footer/>
                  {/*<CursorEffect/>*/}
              </div>
          </div>
      </div>
  );
}

export default App;