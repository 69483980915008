import React from "react";
import styles from "../style";
import { Button1 } from "./Button";
import customer247 from "../assets/247customer.png";
import automationExpert from "../assets/automationExpert.png";
import { make, openai, twilio, vapi, voiceflow, zapier } from "../assets";

const SampleServices = () => {
  const ServiceCard = ({ title, preview, description, image }) => {
    return (
      <div
        className="flex flex-col py-10 px-8 rounded-[24px] bg-[rgb(16,23,41)] cursor-pointer shadow-lg transition-transform transform hover:scale-105 hover:shadow-2xl"
        tabIndex="0"
      >
        {/* Service Title */}
        <h4 className="font-poppins font-semibold text-white text-3xl mb-6 leading-tight">
          {title}
        </h4>
        <div className="flex flex-col lg:flex-row lg:items-start lg:justify-between">
          {/* Service Description */}
          <div className="font-poppins font-normal text-dimWhite text-lg leading-relaxed mt-3 lg:w-1/2 lg:mt-2">
            {description}
          </div>
          {/* Service Image */}
          {image && (
            <div className="lg:w-1/2 lg:pl-8 mt-8 lg:mt-0">
              <img
                src={image}
                alt={title}
                className="rounded-lg transition-all duration-500 w-full max-w-md mx-auto object-cover"
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  const services = [
    {
      title: "Intelligent Lead Generation",
      preview:
        "Revolutionize your sales pipeline with our AI-powered system that identifies and engages high-quality prospects.",
      description: (
        <>
          <p className="mb-4">
            Our lead gen solutions identify and engage high-quality prospects
            to:
          </p>
          <ul className="list-disc list-inside mb-4 space-y-2">
            <li>Increase conversion rates</li>
            <li>Achieve an improvement in close rates</li>
            <li>Boost revenue</li>
          </ul>
          <p>
            Don't waste time on unqualified leads - let our AI provide your
            sales team the right customers, allowing them to close more deals.
            Our service is up 24/7, meaning you never miss an opportunity.
          </p>
        </>
      ),
      image: customer247,
    },
    {
      title: "24/7 AI Customer Service",
      preview:
        "Provide instant, accurate responses to customer inquiries around the clock with our advanced AI chatbot.",
      description: (
        <>
          <p className="mb-4">
            Our AI solutions boost your customer support by providing:
          </p>
          <ul className="list-disc list-inside mb-4 space-y-2">
            <li>Instant, accurate responses 24/7/365</li>
            <li>Improved customer satisfaction</li>
            <li>Significant cost savings by reducing support costs</li>
            <li>Enhanced first-call resolution rates</li>
          </ul>
          <p>
            Don't keep customers waiting. Upgrade to AI-powered support that
            works alongside your team to delight customers and cut costs.
          </p>
        </>
      ),
      image: customer247,
    },
  ];

  const toolLogos = [
    { src: openai, alt: "OpenAI" },
    { src: make, alt: "Make.com" },
    { src: twilio, alt: "Twilio" },
    { src: vapi, alt: "Vapi" },
    { src: voiceflow, alt: "Voiceflow" },
    { src: zapier, alt: "Zapier" }
  ];

  return (
    <section className="w-full py-16">
      {/* Services Section */}
      <div
        id="services"
        className={`${styles.flexCenter} flex-col relative px-6 mb-8`}
      >
        {/* ... keeping the existing services section the same ... */}
      </div>

      {/* Expertise Section - Redesigned */}
      <div
        id="expertise"
        className="w-full max-w-6xl mx-auto px-6 py-16 bg-[rgb(16,23,41)] rounded-[24px]"
      >
        <div className="text-center mb-12">
          <h2 className={`${styles.heading2} text-4xl sm:text-5xl font-bold mb-4`}>
            Our AI & Automation Expertise
          </h2>
          <p className={`${styles.paragraph} text-lg sm:text-xl max-w-3xl mx-auto`}>
            We leverage industry-leading tools and platforms to deliver cutting-edge AI solutions for your business
          </p>
        </div>
        
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-8 items-center justify-items-center">
          {toolLogos.map((logo, index) => (
            <div 
              key={index}
              className="w-full max-w-[160px] h-[80px] flex items-center justify-center p-4 bg-yellow-500 rounded-lg hover:bg-yellow-600 transition-colors"
            >
              <img
                src={logo.src}
                alt={logo.alt}
                className="max-w-full max-h-full object-contain"
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SampleServices;
