import { stanford, websize, buildspace, Alovie, sxfull, georgiatech, voiceflow, msft } from "./assets";
const partners = [
    { name: 'stanford', logo: stanford },
    { name: 'startupexchange', logo: sxfull },
    { name: 'georgiatech', logo: georgiatech },
    { name: 'voiceflow', logo: voiceflow},
    { name: 'buildspace', logo: buildspace },
    { name: "msft", logo: msft}
  
  ];

export default partners