import React from "react";
import styles from "../style";
import { ReactComponent as OneIcon } from "../assets/one.svg";
import { ReactComponent as TwoIcon } from "../assets/two.svg";
import { ReactComponent as ThreeIcon } from "../assets/three.svg";
import leftBottom from '../assets/leftBottom.png';
import rightBottom from '../assets/rightBottom.png';
import middleTop from '../assets/middleTop.png';

const ProcessStep = ({ icon, title, content, image, isThird }) => (
    <div className="flex flex-col items-center justify-between h-full mb-8 sm:mb-0 w-full bg-gray-800 p-6 rounded-lg shadow-lg transform transition-all hover:scale-105 hover:shadow-xl"> {/* Added h-full to ensure equal height */}
        <div className="flex items-center mb-4 w-full justify-center">
            <div className="w-12 h-12 flex-shrink-0 rounded-full bg-yellow-400 flex items-center justify-center">
                <div className="w-8 h-8">
                    {icon}
                </div>
            </div>
        </div>
        <div className="text-center flex-grow"> {/* Added flex-grow to push content towards bottom */}
            <h4 className="font-poppins font-semibold text-white text-[18px] mb-2">
                {title}
            </h4>
            <p className="font-poppins font-normal text-dimWhite text-[14px]">
                {content}
            </p>
        </div>
        {image && (
            <div className="flex justify-center items-center w-full h-full mt-6">
                <img
                    src={image}
                    alt={`${title} Decoration`}
                    className={`rounded-lg ${isThird ? 'max-w-[220px]' : 'max-w-[150px]'}`} // Increased size for the third image
                />
            </div>
        )}
    </div>
);


const Business = () => (
    <section className="relative">
        <div className="absolute inset-0 bg-gray-900 opacity-50 rounded-lg"></div>
        <div className="relative z-10 p-8 rounded-lg border-2 border-yellow-400 shadow-xl" style={{ boxShadow: '0 0 25px 5px rgba(255, 215, 0, 0.3)' }}>
            <h2 className={`${styles.heading2} mb-10 text-center text-white`}>
                Our Process 
            </h2>
            <div className="hidden sm:grid sm:grid-cols-3 sm:gap-6">
                <div className="flex flex-col items-center">
                    <ProcessStep
                        icon={<OneIcon className="w-full h-full" />}
                        title="Plan"
                        content="We first work with our clients to brainstorm solutions"
                        image={leftBottom}
                    />
                </div>
                <div className="flex flex-col items-center">
                    <ProcessStep
                        icon={<TwoIcon className="w-full h-full" />}
                        title="Implement"
                        content="Create solution, receive feedback from client, make changes"
                        image={middleTop}
                    />
                </div>
                <div className="flex flex-col items-center">
                    <ProcessStep
                        icon={<ThreeIcon className="w-full h-full" />}
                        title="Execute"
                        content="Deploy solution and continue to monitor/maintain"
                        image={rightBottom}
                        isThird={true} // Pass prop to indicate this is the third step
                    />
                </div>
            </div>
            <div className="sm:hidden flex flex-col">
                <ProcessStep
                    icon={<OneIcon className="w-full h-full" />}
                    title="Plan"
                    content="We first work with our clients to brainstorm solutions"
                    image={leftBottom}
                />
                <ProcessStep
                    icon={<TwoIcon className="w-full h-full" />}
                    title="Implement"
                    content="Create solution, receive feedback from client, make changes"
                    image={middleTop}
                />
                <ProcessStep
                    icon={<ThreeIcon className="w-full h-full" />}
                    title="Execute"
                    content="Deploy solution and continue to monitor/maintain"
                    image={rightBottom}
                    isThird={true} // Pass prop for third step
                />
            </div>
        </div>
    </section>
);




export default Business;