import { feedback } from "../constants";
import styles from "../style";
import FeedbackCard from "./FeedbackCard";
import { XCircle, CheckCircle } from "lucide-react";

const Testimonials = () => {
  const metrics = [
    { label: "Response Time", result: "< 1 minute" },
    { label: "Conversion Rate", result: "+35%" },
    { label: "Support Capacity", result: "+70%" },
    { label: "Monthly Revenue Impact", result: "+$3,650" },
  ];

  return (
    <>
      {/* Case Study Section */}
      <section className={`${styles.paddingY} flex flex-col items-center w-full min-h-screen bg-primary`}>
        {/* Case Study Header */}
        <div className="w-full max-w-4xl mx-auto px-4 mb-16 text-center pt-12">
          <h2 className="text-4xl sm:text-5xl font-bold text-white mb-4">Case Study:</h2>
          <h3 className="text-2xl sm:text-3xl font-semibold text-gradient mb-4">
            Alovie Technologies – Automating Customer Service and Lead Generation
          </h3>
        </div>

        {/* Case Study Content */}
        <div className="w-full max-w-6xl mx-auto px-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            {/* Left Column - Problem & Solution */}
            <div className="space-y-8">
              <div className="bg-[#14101d] rounded-lg p-8 shadow-lg">
                <h4 className="text-xl font-semibold text-white mb-4">Vertical</h4>
                <p className="text-gray-100 text-lg leading-relaxed">
                  E-Commerce shop <br />(Chromatography and Mass Spectrometry)
                </p>
              </div>

              <div className="bg-[#14101d] rounded-lg p-8 shadow-lg relative">
                <span className="text-red-500 text-2xl">✕</span>
                <h4 className="text-xl font-semibold text-white mb-0">The Challenge</h4>
                <p className="text-gray-100 text-lg leading-relaxed">
                  Alovie Technologies struggled with:
                </p>
                <ul className="list-disc list-inside text-gray-100 text-lg leading-relaxed mt-4 space-y-2">
                  <li>High inquiry volumes & lead generation demands</li>
                  <li>Delays & missed leads from manual qualification</li>
                  <li>Slow response times during peak hours</li>
                </ul>
              </div>

              <div className="bg-[#14101d] rounded-lg p-8 shadow-lg relative">
                <CheckCircle className="text-green-500 text-3xl absolute top-4 right-4" />
                <h4 className="text-xl font-semibold text-white mb-4">Our Solution</h4>
                <p className="text-gray-100 text-lg leading-relaxed">
                  SoloScale introduced an AI-powered automation system offering:
                </p>
                <ul className="list-disc list-inside text-gray-100 text-lg leading-relaxed mt-4 space-y-2">
                  <li>24/7 automated chat and phone support</li>
                  <li>Smart lead scoring and qualification</li>
                  <li>Automated appointment booking</li>
                  <li>Instant, real-time customer support</li>
                </ul>
              </div>
            </div>

            {/* Right Column - Results & Metrics */}
            <div className="space-y-8">
              <div className="bg-[#14101d] rounded-lg p-8 shadow-lg">
                <h4 className="text-xl font-semibold text-gray-300 mb-4">Impact Metrics</h4>
                <div className="grid grid-cols-1 gap-6">
                  {metrics.map((metric, index) => (
                    <div key={index} className="border border-gray-700 rounded-lg p-4 text-center">
                      <h5 className="text-gray-300 font-medium mb-2">{metric.label}</h5>
                      <p className="text-3xl font-bold text-yellow-400">{metric.result}</p>
                    </div>
                  ))}
                </div>
              </div>

              <div className="bg-[#14101d] rounded-lg p-8 shadow-lg">
                <h4 className="text-xl font-semibold text-gray-300 mb-4">Financial Impact</h4>
                <div className="space-y-4">
                  <div className="border border-gray-700 rounded-lg p-4 text-center">
                    <p className="text-gray-300 mb-2">Annual Cost Savings</p>
                    <p className="text-3xl font-bold text-yellow-400">$4,500</p>
                  </div>
                  <div className="border border-gray-700 rounded-lg p-4 text-center">
                    <p className="text-gray-300 mb-2">Monthly Revenue Increase</p>
                    <p className="text-3xl font-bold text-yellow-400">$3,650</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section id="clients" className={`${styles.paddingY} flex flex-col items-center w-full min-h-screen`}>
        {/* Testimonials Header */}
        <div className="w-full max-w-4xl mx-auto px-4 mb-16 text-center pt-12">
          <h2 className="text-4xl sm:text-5xl font-bold text-white mb-4">
            Client Testimonials & Stories
          </h2>
          <p className={`${styles.paragraph} text-lg sm:text-xl max-w-3xl mx-auto`}>
            Discover what our clients have to say about their experience working with us. Your success is our priority.
          </p>
        </div>

        {/* Testimonials Grid */}
        <div className="w-full max-w-6xl mx-auto px-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {feedback.map((card) => (
              <div
                key={card.id}
                className="bg-[#14101d] rounded-lg p-8 shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl"
              >
                <div className="flex items-center mb-6">
                  <img
                    src={card.img}
                    alt={card.name}
                    className="w-16 h-16 rounded-full mr-4 border-2 border-gray-400"
                  />
                  <div>
                    <h4 className="text-xl font-semibold text-white">{card.name}</h4>
                    <p className="text-lg text-gray-400">{card.title}</p>
                  </div>
                </div>
                <p className="text-gray-100 text-lg leading-relaxed mb-2">{card.content}</p>
                <div className="text-right text-gray-400 font-medium">{card.company}</div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonials;
