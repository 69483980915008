import React, { useEffect } from "react";

const Calendly = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <section className="w-full h-[1000px] sm:h-[950px] md:h-[700px] lg:h-[700px]">
      <div 
        className="calendly-inline-widget w-full h-full"
        data-url="https://calendly.com/soloscalesolutions/30min?hide_gdpr_banner=1"
      />
    </section>
  );
};

export default Calendly;
